/* You can add global styles to this file, and also import other style files */

$gutter: 1rem; //for primeflex grid system
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Demos */
@import "assets/demo/styles/flags/flags.css";
@import "assets/demo/styles/badges.scss";
@import "assets/demo/styles/code.scss";

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
// .card {
//   height: calc(100vh - 9rem);
// }
.vertical-tabs .p-tabmenu-nav {
  flex-direction: column;
}

.vertical-tabs .p-tabmenuitem {
  width: 100%;
  text-align: left;
}
.p-tabmenuitem:not(.p-hidden) {
  display: block !important;
}
.p-tabmenu .p-tabmenu-nav {
  border: none !important;
}
.layout-topbar .layout-menu-button {
  display: none !important;
}
@media (max-width: 991px) {
  .layout-topbar .layout-menu-button {
    display: inline-flex !important;
  }
}
.p-calendar {
  width: 100%;
}

@media (min-width: 992px) {
  .layout-wrapper.layout-static .layout-main-container {
    margin-left: 300px;
  }
  .layout-wrapper.layout-static .hide-sidenav {
    margin-left: 0px !important;
  }
}
.text-danger {
  color: #e24c4c;
}

.isMobile {
  margin-left: 1rem;
  text-decoration: underline;
}
